import Opportunity from '@/models/AccountDetails/Opportunity'

export default class OpportunityFactory {
  static createFromJson(json) {
    return new Opportunity(json)
  }

  static createFromJsonArray(jsonArray = []) {
    const jsonData = []
    jsonArray.forEach(item => {
      jsonData.push(OpportunityFactory.createFromJson(item))
    })

    return jsonData
  }
}
